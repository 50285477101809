<template>
	<div class="cbox">
		<div class="headbar">
			<div style="display: flex;">

				首页视频管理


				<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload" @click="add">上传视频</el-button>

			</div>

		</div>
		<div class="bcontent">


			<el-table :data="DataList" stripe border>

				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="cover_url" label="封面" width="100">
					<template slot-scope="scope">

						<img :src="scope.row.cover_url" style="width: 50px;" />

					</template>
				</el-table-column>
				<el-table-column prop="video_url" label="视频url" min-width="100">
				</el-table-column>
				<el-table-column prop="cdate" label="创建时间" width="160">
				</el-table-column>
				<el-table-column prop="sort" label="排序号" width="100">
				</el-table-column>

				<el-table-column fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" style="color: skyblue;" @click="updateDetail(scope.row)">编辑</el-button>
						<el-button type="text" style="color: orangered;" @click="remove(scope.row.id)">删除</el-button>


					</template>
				</el-table-column>



			</el-table>
		</div>
		<div style="padding-top: 10px;display: flex; flex-direction: row-reverse;">


		</div>




		<el-dialog title="视频编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px">
			<el-form ref="form" :model="EditItem" label-width="80px">

				<el-row>
					<el-col :span="20">
						<el-form-item label="视频">

							<video v-if="EditItem.video_url" style="width: 640px; height: 320px; background-color: #000;" controls
								:src="EditItem.video_url">

							</video>

							<div style="width: 640px; height: 10px; background-color: #ccc;border-radius: 5px; margin-bottom: 20px;">
								<div style="width:0px; height: 10px; background-color:orange;border-radius: 5px;" :style="{width:process+'%'}">
							
						</div>
							</div>

							<el-upload style="display: inline-block;" action :http-request="uploadOss"
								:on-success="handleVideoUpload" :on-progress="onProgress" :show-file-list="false" name="image">
								<el-button id="btnimport1" style="margin-left: 10px; "
									icon="el-icon-upload">选择视频文件（只能上传MP4格式视频）</el-button>
							</el-upload>

						</el-form-item>

					</el-col>

					<el-col :span="20">
						<el-form-item label="封面图">
							<img v-if="EditItem.cover_url" style="width: 320px;" controls :src="EditItem.cover_url" />


							<el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadOss"
								:on-success="handleCoverUpload" :show-file-list="false" name="image">
								<el-button id="btnimport1" style="margin-left: 10px; "
									icon="el-icon-upload">上传视频封面</el-button>
							</el-upload>

						</el-form-item>
					</el-col>
					<el-col :span="20">
						<el-form-item label="视频名称">

							<el-input v-model="EditItem.name"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="20">
						<el-form-item label="排序号">

							<el-input v-model="EditItem.sort"></el-input>

						</el-form-item>
					</el-col>



					<el-col :span="24">
						<div style="text-align: center; padding: 50px;">

							<el-button type="primary" @click="saveForm">保存</el-button>
						</div>
					</el-col>
				</el-row>



			</el-form>
		</el-dialog>





	</div>
</template>
  
<script>

export default {
	components: {

	},
	data() {
		return {
			dialogVisible: false,
			EditItem: {
				id: 0,
				video_url: "",
				cover_url: "",
				name:"",
				sort: 99
			},
			DataList: [],
			keyword: "",
			page: {
				current_page: 1,
				next_page: 1,
				pre_page: 1,
				total_page: 1,
				count: 0
			},
			process:0

		};
	},

	mounted() {
		this.getList();


	},
	methods: {
		onSearch() {
			this.getList();
		},
		getList(page) {
			this.$http.post("/api/video_list", {
				keyword: this.keyword,
				page: this.page.current_page
			}).then(
				res => {
					this.DataList = res.data;


				})
		},

		paginate(val) {
			this.page.current_page = val
			this.getList()
		},

		add() {
			this.EditItem = {
				id: 0,
				video_url: "",
				cover_url: "",
				name:"",
				sort: 99
			}
			this.process = 0
				this.dialogVisible = true
		},
		updateDetail(e) {
			this.process = 0
			this.EditItem = e
			this.dialogVisible = true
		},
		handleVideoUpload(e) {

			this.EditItem.video_url = e.src
		},
		onProgress(e){
			console.log(e);
			this.process = Math.round(e.loaded/e.total*1000)/10
		},
		handleCoverUpload(e) {

			this.EditItem.cover_url = e.src
		},

		remove(id) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/video_delete", {
					id: id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功'
					});

					this.getList(this.page.current_page);
				})

			}).catch(() => {

			});
		},
		saveForm() {
			this.$http.post("/api/video_update", this.EditItem).then(res => {
				this.$message({
					type: 'success',
					message: '保存成功'
				});
				this.dialogVisible = false

				this.getList();
			})
		}


	}
}
</script>
  